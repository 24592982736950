exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-505-tsx": () => import("./../../../src/pages/505.tsx" /* webpackChunkName: "component---src-pages-505-tsx" */),
  "component---src-pages-contactanos-gracias-tsx": () => import("./../../../src/pages/contactanos-gracias.tsx" /* webpackChunkName: "component---src-pages-contactanos-gracias-tsx" */),
  "component---src-pages-contactanos-tsx": () => import("./../../../src/pages/contactanos.tsx" /* webpackChunkName: "component---src-pages-contactanos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locales-tsx": () => import("./../../../src/pages/locales.tsx" /* webpackChunkName: "component---src-pages-locales-tsx" */),
  "component---src-pages-mercado-tsx": () => import("./../../../src/pages/mercado.tsx" /* webpackChunkName: "component---src-pages-mercado-tsx" */),
  "component---src-pages-tarjetas-de-regalo-tsx": () => import("./../../../src/pages/tarjetas-de-regalo.tsx" /* webpackChunkName: "component---src-pages-tarjetas-de-regalo-tsx" */),
  "component---src-pages-trabaja-con-nosotros-gracias-tsx": () => import("./../../../src/pages/trabaja-con-nosotros-gracias.tsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-gracias-tsx" */),
  "component---src-pages-trabaja-con-nosotros-tsx": () => import("./../../../src/pages/trabaja-con-nosotros.tsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-tsx" */)
}

